import { Slot } from '@radix-ui/react-slot';
import { HTMLProps } from 'react';
import React from 'react';
import { VariantProps, tv } from 'tailwind-variants';

interface LayoutProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

const pageLayoutVariant = tv({
  base: 'border-gray-2 flex h-dvh w-full overflow-auto',
});

const ReskinLayout = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';

    return (
      <Comp className={pageLayoutVariant({ className })} {...props} ref={ref} />
    );
  },
);

const mainVariant = tv({
  base: [
    'h-full w-full flex-1 overflow-auto',
    'grid grid-cols-[1fr_auto] grid-rows-[auto_1fr_auto]',
  ],
});

const ReskinMain = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'main';

    return <Comp className={mainVariant({ className })} ref={ref} {...props} />;
  },
);

const headerVariant = tv({
  base: [
    'flex h-16 w-full items-center gap-2 border-b p-3 md:gap-6',
    'col-span-2 row-start-1',
  ],
});

const ReskinHeader = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'header';

    return (
      <Comp className={headerVariant({ className })} ref={ref} {...props} />
    );
  },
);

const contentVariant = tv({
  slots: {
    base: [
      'col-start-1 row-start-2 flex h-full w-full justify-center overflow-auto',
    ],
    content: 'p-3',
  },
  variants: {
    variant: {
      container: {
        content: 'container',
      },
      fullWidth: {
        content: 'w-full',
      },
      smallContainer: {
        content: 'mx-auto w-full max-w-[640px]',
      },
    },
  },
  defaultVariants: {
    variant: 'container',
  },
});

interface ContentProps extends LayoutProps {
  variant?: VariantProps<typeof contentVariant>['variant'];
}

const ReskinContent = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    const { base, content } = contentVariant({ variant });

    return (
      <section className={base({ className })}>
        <Comp className={content({ className })} ref={ref} {...props} />
      </section>
    );
  },
);

const footerVariant = tv({
  extend: headerVariant,
  base: ['px-3 md:py-3', 'col-span-1 row-start-3 border-b-0 border-t'],
});

const ReskinFooter = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'footer';

    return (
      <Comp className={footerVariant({ className })} ref={ref} {...props} />
    );
  },
);

export { ReskinLayout, ReskinMain, ReskinHeader, ReskinContent, ReskinFooter };
