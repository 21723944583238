import {
  addDays,
  addHours,
  addMinutes,
  format,
  setHours,
  setMinutes,
} from 'date-fns';
import { ChevronDownIcon, ClockIcon, MoveHorizontalIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { tv } from 'tailwind-variants';

import { Command, CommandGroup, CommandItem } from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { InputVariants, inputVariants } from './variants';

interface TimeRangeSelectProps extends InputVariants {
  /** Date of the time range */
  date?: Date;
  startTime?: Date;
  endTime?: Date;
  onStartTimeChange: (startTime?: Date) => void;
  onEndTimeChange: (endTime?: Date) => void;
  disabled?: boolean;
}

const timeSelectDropdown = tv({
  slots: {
    base: 'border-gray-4 border p-0',
    dropdown:
      'grid max-h-[min(var(--radix-popper-available-height),340px)] w-72 grid-cols-2 grid-rows-[auto,1fr] gap-x-0 p-2',
    label: 'border-b p-2 text-sm',
    group: 'overflow-auto border-r',
    item: 'cursor-pointer font-mono',
  },
  variants: {
    selected: {
      true: {
        item: 'bg-brand-10 text-white',
      },
    },
  },
});

const { base, dropdown, label, group, item } = timeSelectDropdown();

const TimeFormat = 'h:mm a';

export const TimeRangeSelect: React.FC<TimeRangeSelectProps> = ({
  date = new Date(),
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  color,
  size,
  variant,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const TimeOptions = useMemo(() => {
    const timeOptions = [];
    const startTime = setMinutes(setHours(date, 0), 0);
    const endTime = addDays(startTime, 1);

    let time = startTime;
    while (time <= endTime) {
      timeOptions.push(time);
      time = addMinutes(time, 15);
    }

    return timeOptions;
  }, [date]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <button
          className={inputVariants({
            className: 'flex cursor-pointer items-center gap-2',
            color,
            size,
            variant,
            disabled,
          })}
        >
          <ClockIcon size={16} />
          <span className="text-nowrap">
            {startTime ? format(startTime, TimeFormat) : 'Start '}
          </span>
          <MoveHorizontalIcon className="text-gray-10 w-3" />
          <span className="text-nowrap">
            {endTime ? format(endTime, TimeFormat) : 'End'}
          </span>
          <ChevronDownIcon className="ml-auto" size={16} />
        </button>
      </PopoverTrigger>
      <PopoverContent className={base()}>
        <Command className={dropdown()}>
          <span className={label()}>Start Time</span>
          <span className={label()}>End Time</span>

          <CommandGroup className={group()}>
            {TimeOptions.map((time) => {
              const formatted = format(time, TimeFormat);
              const selected =
                startTime && formatted === format(startTime, TimeFormat);

              return (
                <CommandItem
                  key={time.toISOString()}
                  onSelect={() => {
                    onStartTimeChange(time);
                    onEndTimeChange(addHours(time, 1));
                  }}
                  className={item({ selected })}
                  value={`start-time-${formatted}`}
                  ref={(el) => {
                    if (selected && el) {
                      el.scrollIntoView({
                        block: 'center',
                      });
                    }
                  }}
                >
                  {formatted}
                </CommandItem>
              );
            })}
          </CommandGroup>

          <CommandGroup className={group()}>
            {TimeOptions.map((time) => {
              const formatted = format(time, TimeFormat);
              const selected =
                endTime && formatted === format(endTime, TimeFormat);
              const disabled = !startTime || time <= startTime;
              return (
                <CommandItem
                  key={time.toISOString()}
                  onSelect={() => {
                    onEndTimeChange(time);
                    setOpen(false);
                  }}
                  className={item({ selected })}
                  value={`end-time-${formatted}`}
                  disabled={disabled}
                  ref={(el) => {
                    if (selected && el) {
                      el.scrollIntoView({
                        block: 'center',
                      });
                    }
                  }}
                >
                  {formatted}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
