import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { CopyTextButton } from '@eluve/blocks';
import {
  Box,
  Button,
  ColDefBuilder,
  DataTable,
  PageTitle,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { UpdateUserAction } from './UpdateUserAction';

const getUsersQuery = graphql(`
  query getUsers {
    users {
      id
      firstName
      lastName
      email
      createdAt
      tenants(orderBy: { lastAccessedAt: DESC_NULLS_LAST }, limit: 1) {
        tenantId
        userId
        lastAccessedAt
      }
    }
  }
`);

type UserRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  lastAccessedAt?: string;
};

const cols = new ColDefBuilder<UserRow>()
  .colDef({
    id: 'id',
    accessorKey: 'id',
    header: 'Id',
    cell: ({ row }) => <CopyTextButton copyText={row.original.id} />,
  })
  .defaultSortable('firstName', 'First Name')
  .defaultSortable('lastName', 'Last Name')
  .defaultSortable('email', 'Email')
  .dateSortable('createdAt', 'Created At')
  .dateSortable('lastAccessedAt', 'Last Accessed At')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => (
      <Box hStack>
        <UpdateUserAction
          userId={row.original.id}
          defaultValues={row.original}
        />
        <Link to={`/admin/users/${row.original.id}`}>
          <Button>View</Button>
        </Link>
      </Box>
    ),
  })
  .build();

export const UsersTable: React.FC = () => {
  const {
    data: { users },
  } = useSuspenseQuery(getUsersQuery);

  return (
    <div>
      <PageTitle>All Users</PageTitle>
      <DataTable
        data={users.map((user) => ({
          ...user,
          lastAccessedAt: user.tenants[0]?.lastAccessedAt,
        }))}
        columns={cols}
        enableGlobalSearch
        initialSortingState={[{ id: 'createdAt', desc: true }]}
      />
    </div>
  );
};
