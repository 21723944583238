import S from 'fluent-json-schema';
import { z } from 'zod';

const baseBlock = z.object({
  key: z.string(),
  label: z.string(),
  description: z.string().optional(),
});

const textField = baseBlock.extend({
  type: z.literal('text'),
  placeholderText: z.string().optional(),
  text: z.string().optional(),
});

export type TextFieldBlock = z.infer<typeof textField>;

const checkbox = baseBlock.extend({
  type: z.literal('checkbox'),
  options: z.array(
    z.object({
      label: z.string(),
      isChecked: z.boolean(),
    }),
  ),
});

export type CheckboxBlock = z.infer<typeof checkbox>;

const groupableBlocks = z.discriminatedUnion('type', [textField, checkbox]);

type RecursiveGroup = z.infer<typeof baseBlock> & {
  type: 'group';
  label: string;
  description?: string;
  blocks: Array<RecursiveGroup | z.infer<typeof groupableBlocks>>;
};

const group: z.ZodType<RecursiveGroup> = baseBlock.extend({
  type: z.literal('group'),
  blocks: z.array(z.union([groupableBlocks, z.lazy(() => group)])),
});

export type GroupBlock = z.infer<typeof group>;

const block = z.union([group, groupableBlocks]);
export type Block = z.infer<typeof block>;
export type BlockType = Block['type'];

const dynamicArtifactTemplate = z.object({
  name: z.string(),
  description: z.string().optional(),
  blocks: z.array(block),
});

export type DynamicArtifactTemplate = z.infer<typeof dynamicArtifactTemplate>;

export const convertDynamicArtifactToJsonSchema = (
  artifact: DynamicArtifactTemplate,
) => {
  const { blocks } = artifact;
  let schema = S.object().description(artifact.description ?? artifact.name);

  blocks.forEach((block) => {
    switch (block.type) {
      case 'group': {
        let groupObj = S.object();

        block.blocks.forEach((groupBlock) => {
          switch (groupBlock.type) {
            case 'text': {
              let prop = S.string();
              prop = groupBlock.description
                ? prop.description(groupBlock.description)
                : prop;

              groupObj = groupObj.prop(groupBlock.key, prop);
              break;
            }
            case 'checkbox':
              groupObj = groupObj.prop(
                groupBlock.key,
                S.object().prop('type', S.const('checkbox')),
              );
              break;
          }
        });

        schema = schema.prop(block.key, groupObj);

        break;
      }
      case 'text': {
        let prop = S.string();
        prop = block.description ? prop.description(block.description) : prop;
        schema = schema.prop(block.key, prop);
        break;
      }
      case 'checkbox':
        schema = schema.prop(
          block.key,
          S.object().prop('type', S.const('checkbox')),
        );
        break;
    }
  });

  return schema;
};
