import React from 'react';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  H3,
  P as Paragraph,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const promptTemplateTextFragment = graphql(`
  fragment PromptTemplateText on PromptTemplates {
    __typename
    id
    template
  }
`);

export const PromptTemplateDetailsPromptTextPage: React.FC = () => {
  const { promptTemplateId } = useParams() as { promptTemplateId: string };
  return (
    <PromptTemplateDetailsPromptText promptTemplateId={promptTemplateId} />
  );
};

export interface PromptTemplateDetailsPromptTextProps {
  promptTemplateId: string;
}

export const PromptTemplateDetailsPromptText: React.FC<
  PromptTemplateDetailsPromptTextProps
> = ({ promptTemplateId }) => {
  const { template } = useCompleteFragment({
    fragment: promptTemplateTextFragment,
    key: { id: promptTemplateId },
  });

  if (!template) {
    return (
      <Alert>
        <AlertTitle>Prompt Template Not Available</AlertTitle>
        <AlertDescription>
          This is a legacy template that should be disabled.
        </AlertDescription>
      </Alert>
    );
  }

  return (template as []).map((t: Record<string, string>, index) => (
    <div key={index}>
      <div onClick={() => navigator.clipboard.writeText(t.content ?? '')}>
        <H3 p="pb-1" cn="hover:cursor-pointer">
          {t.role}
        </H3>
      </div>
      <div className="bg-gray-3 mb-8 flex max-h-[700px] flex-col gap-4 overflow-y-scroll rounded-md p-5">
        <Paragraph>
          <pre className="text-wrap">{t.content}</pre>
        </Paragraph>
      </div>
    </div>
  ));
};
