import { useQuery } from '@apollo/client';
import { ArrowLeftIcon } from 'lucide-react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  H4,
  Label,
  PageTitle,
  Stat,
  subHeaderTextStyles,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { formatHumanName } from '@eluve/utils';

import { UpdateUserAction } from './UpdateUserAction';

const GetUserInfoQuery = graphql(`
  query gerUserInfo($id: uuid!) {
    usersByPk(id: $id) {
      id
      firstName
      lastName
      email
      createdAt
      appointmentsAggregate {
        aggregate {
          count
        }
      }
      tenants {
        userId
        role
        tenantId
        tenant {
          name
        }
      }
    }
  }
`);

export const UserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { loading, data, error } = useQuery(GetUserInfoQuery, {
    variables: {
      id: userId ?? 'no-id',
    },
  });

  return (
    <div>
      <Box hStack className="gap-3">
        <Link to="/admin/users">
          <Button variant="outline" size="icon">
            <ArrowLeftIcon />
          </Button>
        </Link>
        <PageTitle>User</PageTitle>
      </Box>

      <Box hStack className="ml-14">
        {loading && <Box hStack>Loading ...</Box>}
        {error && (
          <H4>
            <span className="text-red-500">Unable to find User</span>
          </H4>
        )}
      </Box>

      {data && (
        <Box vStack>
          <Box hStack className="items-start gap-6">
            <div className="w-112 flex rounded-lg p-6 shadow-md">
              <Box vStack className="flex-1 gap-6">
                <Label>
                  Name
                  <p
                    className={subHeaderTextStyles({
                      className: 'text-gray-11',
                    })}
                  >
                    {formatHumanName(
                      data.usersByPk?.firstName,
                      data.usersByPk?.lastName,
                    )}
                  </p>
                </Label>
                <Label>
                  Email
                  <p
                    className={subHeaderTextStyles({
                      className: 'text-gray-11',
                    })}
                  >
                    {data.usersByPk?.email}
                  </p>
                </Label>
              </Box>
              {userId && (
                <UpdateUserAction
                  userId={userId}
                  defaultValues={{
                    firstName: data.usersByPk?.firstName ?? '',
                    lastName: data.usersByPk?.lastName ?? '',
                    email: data.usersByPk?.email ?? '',
                  }}
                />
              )}
            </div>
            <Box className="w-96">
              <Stat
                name="Associated Appointments"
                stat={
                  data.usersByPk?.appointmentsAggregate?.aggregate?.count ?? '-'
                }
              />
            </Box>
          </Box>
          <Box vStack className="w-full rounded-lg p-6 shadow-md">
            <H4>Associated Tenants</H4>
            <Divider />
            <Box className="grid grid-cols-2 gap-x-8 gap-y-3">
              <b>Name</b>
              <b>Role</b>
              {data.usersByPk?.tenants.map(({ role, tenantId, tenant }) => (
                <React.Fragment key={tenantId}>
                  <Link
                    to={`/admin/tenants/${tenantId}`}
                    className="hover:underline"
                  >
                    {tenant.name}
                  </Link>
                  <p>{role}</p>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};
