import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import { Box, Select, SelectContent, SelectTrigger } from '@eluve/components';
import {
  PromptTemplateSelectItem,
  getUserSettingsQuery,
} from '@eluve/frontend-feature-user-settings';
import { LlmOutputTypesLookup } from '@eluve/graphql-types';
import { useNamedLogger } from '@eluve/logger';
import { useUserIdFromSession } from '@eluve/session-helpers';

interface TemplateSelectorProps {
  selectedTemplateId?: string;
  onTemplateSelected?: (templateId: string) => void;

  selectedOutputLlmTemplateId?: string;
  onSelectedOutputLlmTemplateId?: (templateId: string) => void;
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  selectedTemplateId,
  onTemplateSelected,
  selectedOutputLlmTemplateId,
  onSelectedOutputLlmTemplateId,
}) => {
  const logger = useNamedLogger('TemplateSelector');
  const userId = useUserIdFromSession();
  const { loading, data } = useQuery(getUserSettingsQuery, {
    variables: { userId },
    fetchPolicy: 'cache-first',
  });

  const templates = useMemo(() => data?.activePromptTemplates ?? [], [data]);

  const defaultTemplateId = useMemo(() => {
    // Check to see if the user's preferered template is available in this context
    const templates = data?.activePromptTemplates ?? [];

    if (templates.length === 0) {
      logger.warn('No templates available for user');
    }

    const { promptTemplateId } = data?.usersByPk?.userSettings ?? {};
    const template = templates.find((t) => t.id === promptTemplateId);
    if (template) {
      return template.id;
    }
    // If no user preference was found, fallback to whatever the current
    // default system template is otherwise just pick the first one
    return templates.find((t) => t.isCurrentDefault)?.id ?? templates[0]?.id;
  }, [data, logger]);

  useEffect(() => {
    if (
      defaultTemplateId &&
      !selectedTemplateId &&
      onTemplateSelected &&
      selectedTemplateId !== defaultTemplateId
    ) {
      onTemplateSelected(defaultTemplateId);
    }
  }, [defaultTemplateId, onTemplateSelected, selectedTemplateId]);

  const fetchingTemplates = templates.length === 0 && loading;

  const selectedTemplate = useMemo(
    () => templates.find((template) => template.id === selectedTemplateId),
    [selectedTemplateId, templates],
  );

  const selectedLlmOutputTemplate = useMemo(
    () =>
      data?.llmOutputTemplates.find(
        (template) => template.id === selectedOutputLlmTemplateId,
      ),
    [data, selectedOutputLlmTemplateId],
  );

  const selectedTemplateOutputType = selectedTemplate?.outputType ?? null;

  const availableLlmOutputTemplates = data?.llmOutputTemplates ?? [];

  return (
    <Box vStack className="w-full">
      <Select value={selectedTemplateId} onValueChange={onTemplateSelected}>
        <SelectTrigger>
          {fetchingTemplates
            ? 'Loading Templates...'
            : selectedTemplate?.name ?? 'Select specialty'}
        </SelectTrigger>
        <SelectContent>
          {templates?.map((template) => (
            <PromptTemplateSelectItem
              key={template.id}
              templateId={template.id ?? ''}
              templateName={template.name ?? ''}
              templateDescription={template.description}
            />
          ))}
        </SelectContent>
      </Select>

      {onSelectedOutputLlmTemplateId &&
        selectedTemplateOutputType !== LlmOutputTypesLookup.SOAP_NOTE && (
          <Select
            disabled={
              selectedTemplateOutputType === LlmOutputTypesLookup.SOAP_NOTE
            }
            value={selectedOutputLlmTemplateId}
            onValueChange={onSelectedOutputLlmTemplateId}
          >
            <SelectTrigger>
              {(() => {
                if (fetchingTemplates) {
                  return 'Loading template outputs...';
                }
                return (
                  selectedLlmOutputTemplate?.name ?? 'Select output template'
                );
              })()}
            </SelectTrigger>
            <SelectContent>
              {availableLlmOutputTemplates?.map((template) => (
                <PromptTemplateSelectItem
                  key={template.id}
                  templateId={template.id ?? ''}
                  templateName={template.name ?? ''}
                  templateDescription={null}
                />
              ))}
            </SelectContent>
          </Select>
        )}
    </Box>
  );
};
