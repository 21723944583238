import { PopoverTrigger } from '@radix-ui/react-popover';
import { format } from 'date-fns';
import { CalendarIcon, ChevronDown } from 'lucide-react';
import { useState } from 'react';

import { Calendar } from './calendar';
import { Popover, PopoverContent } from './popover';
import { InputVariants, inputVariants } from './variants';

interface SingleDateSelectorProps extends InputVariants {
  date?: Date;
  onDateChange: (date?: Date) => void;
  className?: string;
}

export const SingleDateSelector: React.FC<SingleDateSelectorProps> = ({
  date,
  onDateChange,
  className,
  color,
  size,
  variant,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleDateChange = (date?: Date) => {
    onDateChange(date);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          className={inputVariants({
            className: [
              'flex cursor-pointer items-center justify-between gap-2',
              className,
            ],
            color,
            size,
            variant,
            disabled,
          })}
        >
          <CalendarIcon size={16} />
          {date ? format(date, 'PP') : <span>Select a date</span>}
          <ChevronDown className="ml-auto" size={16} />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};
